import { MenuItem, Order } from "./Order";
import { Client, ServiceUser } from "./User";

export interface Location {
    id: number;
    clientId: number;
    client: Client;
    name: string;
    description: string;
    imageUrl: string;
    menuItems: MenuItem[];
    categories: Category[];
    orders: Order[];
    serviceUsers: ServiceUser[];
    isDemo: boolean;
    serviceType: ServiceType;
    settings: LocationSettings;
}

export interface LocationSettings {
    id: number;
    isPickupTimeEnabled: boolean;
    isOnlineServiceEnabled: boolean;
    deActivationMsg: string;
    showTimeInManagementApp: boolean;
}

export interface Category {
    id: number;
    name: string;
    location: string;
    locationId: number;
    arrangmentNr: number;
}

export interface ApiResponse {
    isSuccessful: boolean;
    message: string;
}

export interface FCM {
    token: string;
    client: Client;
}

export enum ServiceType {
    DineIn = "DINE_IN",
    Pickup = "PICKUP",
    ALL = "ALL",
}
