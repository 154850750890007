import { api } from "../axios";
import { ApiResponse, FCM } from "../types";

export async function getServiceUser(uid: string) {
    const { data } = await api.get(`/service-user/${uid}`);
    return data;
}

export const registerDevice = async (fcm: FCM) => {
    const { data } = await api.post<ApiResponse>(`/client/device`, fcm);
    return data;
};

export const unRegisterDevice = async (fcmToken: string) => {
    const { data } = await api.delete<ApiResponse>(`/client/device/${fcmToken}`);
    return data;
};
