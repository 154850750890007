import { Shadows, ThemeOptions, createTheme } from "@mui/material";

const defaultTheme = createTheme();
const defaultShadows: ThemeOptions["shadows"] = [...defaultTheme.shadows];

export const theme: ThemeOptions = createTheme({
    typography: {
        fontFamily: ["Satoshi", "sans-serif"].join(","),
    },
    shape: {
        borderRadius: 16,
    },
    shadows: defaultShadows.map(() => "none") as Shadows,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: "#0085EB",
            light: "#2a9ef7",
        },
        secondary: {
            main: "#f2f2f2",
        },
        info: {
            main: "#2a9ef7",
            contrastText: "#ffffff",
        },
        background: {
            default: "#F6F6F6",
            paper: "#FFFFFF",
        },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
                colorSecondary: {
                    color: "#29323B",
                    borderColor: "#e3e1e1",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    ":last-child": {
                        paddingBottom: "8px",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#D9D9D9",
                    fontSize: 20,
                    fontWeight: 400,
                    "&.Mui-selected": {
                        color: "#fff",
                        fontWeight: 600,
                    },
                },
            },
        },
    },
});
