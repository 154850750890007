import { Box, Typography } from "@mui/material";

export default function NoData() {
    return (
        <Box
            width={"100%"}
            height={200}
            bgcolor={"#fff"}
            borderRadius={1}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Typography fontWeight={600} color={"primary.main"}>
                No Orders
            </Typography>
        </Box>
    );
}
