import { toast } from "react-toastify";
import queryClient from "../../../QueryClient";
import { acceptOrder, updateStatus } from "../../../services/order.service";
import { Order, OrderStatus } from "../../../types/Order";

export async function handleUpdate(id: number, status: OrderStatus) {
    try {
        await updateStatus(id, status);
        toast.success(`Order #${id} udpated to ${status}`);
        queryClient.invalidateQueries();
    } catch (error) {
        console.error(error);
        toast.error(`There was an Error updating Order ${id}`);
    }
}

export async function handleAcceptOrder(id: number, minutes: number) {
    try {
        await acceptOrder(id, minutes);
        toast.success(`Order #${id} accepted`);
        queryClient.invalidateQueries();
    } catch (error) {
        console.error(error);
        toast.error(`There was an Error updating Order ${id}`);
    }
}


export async function handlePrintOrder(order: Order) {
    try {
        await updateStatus(order.id, OrderStatus.OrderToBePrinted);
        toast.success(`Order #${order.id} printed`);
        queryClient.invalidateQueries();
    } catch (error) {
        console.error(error);
        toast.error(`There was an Error printing Order #${order.id}`);
    }
}
