import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	List,
	ListItem,
	Typography,
	Box,
	CircularProgress,
	Chip,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { handleUpdate } from "./helpers/OrderAccordion.helpers";
import { Order, OrderStatus } from "../../types/Order";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import { theme } from "../../config/theme";

export default function OrderAccordion({
	order,
	refetchOrders,
}: {
	order: Order;
	refetchOrders: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<Order[], unknown>>;
}) {
	const [loading, setLoading] = useState(false);

	const handleActions = async (id: number, status: OrderStatus) => {
		setLoading(true);
		await handleUpdate(id, status);
		await refetchOrders();
		setLoading(false);
	};

	return (
		<Accordion defaultExpanded={true} sx={{ borderRadius: 1, overflow: "hidden" }}>
			<AccordionSummary
				sx={{
					minHeight: "0!important",
					backgroundColor: "#d9d9d9",
					"& > .MuiAccordionSummary-content": { my: "8px !important" },
				}}
				expandIcon={<ExpandMoreIcon />}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-around"}
					gridTemplateColumns={"1fr 1fr"}
					width={"100%"}
				>
					<Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} width={"100%"}>
						<Typography variant="h4" fontWeight={600}># {order.id}</Typography>
						<Typography>{order.userPhoneNumber}</Typography>
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ pt: 1, pb: 1.5, px: 3 }}>
				<List dense>
					{order.orderItems.map((orderItem) => (
						<>
							<ListItem key={orderItem.id} dense sx={{ px: 0 }}>
								<Box display="grid" gridTemplateColumns={"auto 1fr"} gap={2}>
									<Typography fontWeight={600}>{`${orderItem.quantity}x`}</Typography>
									<Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} gap={1}>
										<Typography fontWeight={600}>{orderItem.menuItem.name}</Typography>
										<Box display={"flex"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
											{orderItem.selectedMenuItemAddOns &&
												orderItem.selectedMenuItemAddOns.map((addOn) => (
													<Chip
														label={addOn.name}
														sx={{
															backgroundColor: addOn.addOnCategory.color,
															// @ts-ignore
															color: theme.palette.getContrastText(
																addOn.addOnCategory.color
															),
															p: 0.7,
															borderRadius: 0.4,
														}}
													/>
												))}
										</Box>
										<Typography color={"#757575"}>{`CHF ${orderItem.menuItem.price.toFixed(
											2
										)}`}</Typography>
									</Box>
								</Box>
							</ListItem>
							<Divider variant="middle" />
						</>
					))}
				</List>
				<Box p={0.5}>
					<Typography textAlign={"center"} fontWeight={600}>
						Total: CHF {order.totalPrice.toFixed(2)}
					</Typography>
				</Box>
				{loading ? (
					<Box p={0.5} display={"flex"} justifyContent={"center"} alignItems={"center"} py={1.2}>
						<CircularProgress size={30} />
					</Box>
				) : (
					<Box p={0.5} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
						{[OrderStatus.OrderOpen, OrderStatus.OrderToBePrinted].includes(order.status) && (
							<Button
								variant="contained"
								sx={{
									backgroundColor: "#04C900",
									color: "#fff",
									fontWeight: 600,
									px: 1.6,
									py: 1.1,
									"&:hover": {
										backgroundColor: "#049900",
									},
								}}
								onClick={() =>
									handleActions(
										order.id,
										OrderStatus.OrderReady
									)
								}
							>Erledigt</Button>
						)}
						{order.status === OrderStatus.OrderReady && (
							<Button
								variant="contained"
								sx={{
									backgroundColor: "#04C900",
									color: "#fff",
									fontWeight: 600,
									px: 1.6,
									py: 1.1,
									"&:hover": {
										backgroundColor: "#049900",
									},
								}}
								onClick={() => handleActions(order.id, OrderStatus.OrderCompleted)}
							>
								Erledigt
							</Button>
						)}
						{order.status === OrderStatus.OrderWaitingForApproval && (
							<Box display={"flex"} justifyContent={"space-between"} width={90}>
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#FF3939",
										color: "#fff",
										p: 1.1,
										minWidth: "0",
										"&:hover": {
											backgroundColor: "#cc3939",
										},
									}}
									onClick={() => handleActions(order.id, OrderStatus.OrderNotAccepted)}
								>
									<HighlightOffIcon />
								</Button>
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#04C900",
										color: "#fff",
										p: 1.1,
										minWidth: "0",
										"&:hover": {
											backgroundColor: "#049900",
										},
									}}
									onClick={() => handleActions(order.id, OrderStatus.OrderOpen)}
								>
									<CheckCircleOutlineIcon />
								</Button>
							</Box>
						)}
					</Box>
				)}
			</AccordionDetails>
		</Accordion>
	);
}
