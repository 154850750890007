import { api } from "../axios";
import { Order, OrderStatus } from "../types/Order";

export async function getOrdersByLocationId(locationId: number): Promise<Order[]> {
    const { data } = await api.get<Order[]>(`/order/location/${locationId}`);
    return data;
}

export async function getOrdersByClientId(clientId: number): Promise<Order[]> {
    const { data } = await api.get<Order[]>(`/order/client/${clientId}`);
    return data;
}

export async function updateStatus(orderId: number, newStatus: OrderStatus): Promise<any> {
    const { data } = await api.put("/order/status", { orderId, newStatus });
    return data;
}

export async function acceptOrder(orderId: number, minutes: number): Promise<any> {
    const { data } = await api.put(`/order/accept/${orderId}/${minutes}`);
    return data;
}
