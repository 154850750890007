import { Location } from ".";
import { MenuItemAddOn } from "./AddOn";

export interface Order {
    id: number;
    userId: string;
    userPhoneNumber: string;
    order_created: string;
    payment_received: string;
    order_picked_up: string;
    totalPrice: number;
    status: OrderStatus;
    orderItems: OrderItem[];
    pickupTime: string;
    orderType: OrderType;
    location: Location;
}

export interface OrderItem {
    id: number;
    menuItem: MenuItem;
    pricePerQuantity: number;
    quantity: number;
    selectedMenuItemAddOns: MenuItemAddOn[];
    totalPrice: number;
}

export interface MenuItem {
    description: string;
    id: number;
    imageUrl: string;
    name: string;
    price: number;
}

export interface Attribute {
    id: number;
    name: string;
    priceChange: number;
    shortName: string;
}

export enum OrderStatus {
    OpenPayment = "WAITING_FOR_PAYMENT",
    OrderOpen = "ORDER_IN_PROGRESS",
    OrderWaitingForApproval = "WAITING_FOR_APPROVAL",
    OrderNotAccepted = "ORDER_DENIED",
    OrderReady = "ORDER_READY_FOR_PICKUP",
    OrderCompleted = "ORDER_COMPLETED",
    OrderToBePrinted = "ORDER_TO_BE_PRINTED",
}

export enum OrderType {
    DineIn = "DINE_IN",
    Pickup = "PICKUP",
}

export interface OrderFilter {
    date: number;
    month: number;
    year: number;
}
