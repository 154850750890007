import { Box } from "@mui/material";

function Loader() {
    return (
        <Box
            position={"absolute"}
            top={0}
            left={0}
            height={"100%"}
            width={"100%"}
            bgcolor={"primary.main"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            zIndex={100}
        >
            <Box className="dot1" width={16} height={16} borderRadius={"50%"}></Box>
            <Box className="dot2" width={16} height={16} borderRadius={"50%"}></Box>
            <Box className="dot3" width={16} height={16} borderRadius={"50%"}></Box>
        </Box>
    );
}

export default Loader;
