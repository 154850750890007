import { Alert, Box, Button, Card, CardContent, Grid, TextField, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/AuthContext";

export default function SignInPage() {
    const { user, signIn } = useUserAuth();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const navigate = useNavigate();
    const [alert, setAlert] = useState<JSX.Element>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            console.log(user);
            navigate(`/${user.client.id}`);
        }
    }, [user, navigate]);

    const onSubmit = async (data: { email: string; password: string }) => {
        try {
            setLoading(true);
            const user = await signIn(data.email, data.password);
            navigate(`/${user.client.id}`);
        } catch (e: any) {
            setAlert(<Alert severity="error">{e.message}</Alert>);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
                backgroundColor: "primary.main",
            }}
        >
            <Grid item xs={12} md={6} lg={4}>
                <Card>
                    <CardContent>
                        <Box display="flex" justifyContent="start" alignItems="center" pb={3}>
                            <Box sx={{ textAlign: "end", alignItems: "center" }}>
                                <img src="/logo.png" style={{ height: 64, width: 64 }} alt="Logo" />
                            </Box>
                            <Box sx={{ textAlign: "start", alignItems: "center", ml: 3 }}>
                                <Typography fontWeight={600} variant="h4" color="primary">
                                    Order Management Tool
                                </Typography>
                                <Typography fontWeight={600} variant="h4" pb={1}>
                                    Sign In
                                </Typography>
                            </Box>
                        </Box>
                        {alert}
                        <Controller
                            control={control}
                            name="email"
                            render={({ field }) => (
                                <TextField variant="outlined" label="Email" sx={{ pb: 1 }} fullWidth {...field} />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="Password"
                                    sx={{ pb: 1 }}
                                    type="password"
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={handleSubmit(onSubmit)}
                        >
                            {loading ? <CircularProgress size={22} color="inherit" /> : "Sign In"}
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
