import { Alert, Box, Grid, Typography, useMediaQuery, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getOrdersByClientId } from "../services/order.service";
import { useState, useEffect } from "react";
import OrderAccordion from "../components/OrderAccordion/OrderAccordion";
import { Order, OrderStatus } from "../types/Order";
import Loader from "../components/Loader/Loader";
import NoData from "../components/NoData/NoData";
import { useUserAuth } from "../context/AuthContext";
import { ServiceType } from "../types";
import LogoutSvg from "../assets/icons/logout.svg";

const activeTabProps = (isActive: boolean) =>
    isActive
        ? {
              bgcolor: "#fff",
              color: "primary.main",
          }
        : {};

const statusTabs = ["Neue Bestellung", "Im Prozess", "Bereit zur Abholung"];

export default function OrderOverviewPage() {
    const { user, signOut } = useUserAuth();
    const [locationsTab, setLocationsTab] = useState(0);
    const [statusTab, setStatusTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isNewOrder, setIsNewOrder] = useState<{ [locationId: number]: boolean }>();
    const [orders, setOrders] = useState<{ [locationId: number]: Order[] }>();
    const [ordersWaitingForApproval, setOrdersWaitingForApproval] = useState<{ [locationId: number]: Order[] }>();
    const [ordersInProgress, setOrdersInProgress] = useState<{ [locationId: number]: Order[] }>();
    const [ordersReadyForPickup, setOrdersReadyForPickup] = useState<{ [locationId: number]: Order[] }>();
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

    if (!user) return <></>;

    if (!user.client.locations[locationsTab].id || isNaN(user.client.locations[locationsTab].id)) {
        return <>Location not found</>;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!orders) return;
        let temp: { [locationId: number]: Order[] } = {};
        user.client.locations.forEach((location) => {
            temp = {
                ...temp,
                [location.id]: orders[location.id].filter(
                    (order) => order.status === OrderStatus.OrderWaitingForApproval
                ),
            };
        });
        setOrdersWaitingForApproval(temp);
        temp = {};
        user.client.locations.forEach((location) => {
            temp = {
                ...temp,
                [location.id]: orders[location.id].filter((order) =>
                    [OrderStatus.OrderOpen, OrderStatus.OrderToBePrinted].includes(order.status)
                ),
            };
        });
        setOrdersInProgress(temp);
        temp = {};
        user.client.locations.forEach((location) => {
            temp = {
                ...temp,
                [location.id]: orders[location.id].filter((order) => order.status === OrderStatus.OrderReady),
            };
        });
        setOrdersReadyForPickup(temp);
        setLoading(false);
    }, [orders, user.client.locations]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { refetch, isLoading, error } = useQuery<Order[]>(
        ["orders/client", user.client.id],
        async () => await getOrdersByClientId(user.client.id),
        {
            onSuccess: (data) => {
                let temp: { [locationId: number]: Order[] } = {};
                user.client.locations.forEach((location) => {
                    temp = {
                        ...temp,
                        [location.id]: data.filter((order) => order.location.id === location.id),
                    };
                });
                setOrders((prev) => {
                    Object.keys(temp).forEach((_locationId) => {
                        const locationId = parseInt(_locationId);
                        if (
                            user.client.locations[locationsTab].id !== locationId &&
                            prev &&
                            temp[locationId].length > prev[locationId].length
                        )
                            setIsNewOrder((prev) => ({ ...prev, [locationId]: true }));
                    });
                    return temp;
                });
            },
            refetchInterval: 15000,
        }
    );

    const handleLocationTabChange = (tab: number) => {
        if ([user.client.locations[tab].id])
            setIsNewOrder((prev) => ({
                ...prev,
                [user.client.locations[tab].id]: false,
            }));
        setStatusTab(0);
        setLocationsTab(tab);
    };

    const handleStatusTabChange = (tab: number) => {
        // if ([user.client.locations[tab].id])
        //     setIsNewOrder((prev) => ({
        //         ...prev,
        //         [user.client.locations[tab].id]: false,
        //     }));
        setStatusTab(tab);
    };

    return (
        <Box
            sx={{ height: "100vh", py: 3, backgroundColor: "primary.main", overflow: "hidden" }}
            display={"flex"}
            flexDirection={"column"}
        >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} px={3} mb={2}>
                <Typography color="secondary" fontWeight={600} variant="h3">
                    Orders
                </Typography>
                <Tooltip title="Logout" onClick={signOut} style={{ cursor: "pointer" }}>
                    <img src={LogoutSvg} alt="logout" width={27} />
                </Tooltip>
            </Box>

            {/* use 864 if we want same width for cards. */}
            {/* <Box px={3} sx={{ maxWidth: tab === 0 ? 1280 : 864 }}> */}
            <Box
                px={3}
                sx={{ maxWidth: locationsTab === 0 ? 1280 : 960 }}
                height={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
            >
                <Box
                    sx={{
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                        display: "flex",
                        cursor: "pointer",
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "primary.main",
                            color: "#fff",
                            border: "1px solid white",
                        }}
                    >
                        {user.client.locations.map((location, index) => (
                            <Box
                                key={location.id}
                                py={1.4}
                                px={3.2}
                                fontWeight={600}
                                sx={{
                                    position: "relative",
                                    transition: "all 0.3s",
                                    borderRadius: "inherit",
                                    "&:first-child": { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                                    "&:last-child": { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
                                }}
                                {...activeTabProps(locationsTab === index)}
                                onClick={() => handleLocationTabChange(index)}
                            >
                                {location.name}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: -6,
                                        right: 0,
                                        width: 18,
                                        height: 18,
                                        borderRadius: "50%",
                                        backgroundColor: "#f00",
                                        display: isNewOrder && isNewOrder[location.id] ? "block" : "none",
                                    }}
                                ></Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                {isMobile && (
                    <Box
                        sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: 3,
                            display: "flex",
                            cursor: "pointer",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "primary.main",
                                color: "#fff",
                                border: "1px solid white",
                            }}
                        >
                            {statusTabs
                                .filter((status, index) => {
                                    return user.client.locations[locationsTab].serviceType !== ServiceType.DineIn
                                        ? true
                                        : index === 2
                                        ? false
                                        : true;
                                })
                                .map((status, index) => (
                                    <Box
                                        key={status}
                                        py={1.4}
                                        px={1.5}
                                        fontWeight={600}
                                        textAlign={"center"}
                                        sx={{
                                            position: "relative",
                                            transition: "all 0.3s",
                                            borderRadius: 0,
                                            borderRight: 1,
                                            "&:first-child": {
                                                borderTopLeftRadius: "inherit",
                                                borderBottomLeftRadius: "inherit",
                                            },
                                            "&:last-child": {
                                                borderTopRightRadius: "inherit",
                                                borderBottomRightRadius: "inherit",
                                            },
                                        }}
                                        {...activeTabProps(statusTab === index)}
                                        onClick={() => handleStatusTabChange(index)}
                                    >
                                        {status}
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                )}
                <Box>{error ? <Alert severity="error">There was an Error Loading the Elements</Alert> : null}</Box>
                <Grid
                    container
                    spacing={2}
                    height={isMobile ? "calc(100% - 200px)" : "calc(100% - 130px)"}
                    position={"relative"}
                >
                    {isLoading || loading ? <Loader /> : null}
                    {(!isMobile || statusTab === 0) && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={user.client.locations[locationsTab].serviceType === ServiceType.DineIn ? 6 : 4}
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Typography variant="h5" color={"#fff"} textAlign={"center"} fontWeight={600} mb={1}>
                                Neue Bestellung
                            </Typography>
                            <Box height={"100%"} overflow={"auto"} pr={0.5}>
                                {ordersWaitingForApproval &&
                                ordersWaitingForApproval[user.client.locations[locationsTab].id].length > 0 ? (
                                    ordersWaitingForApproval[user.client.locations[locationsTab].id].map((order) => (
                                        <OrderAccordion key={order.id} order={order} refetchOrders={refetch} />
                                    ))
                                ) : (
                                    <NoData />
                                )}
                            </Box>
                        </Grid>
                    )}
                    {(!isMobile || statusTab === 1) && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={user.client.locations[locationsTab].serviceType === ServiceType.DineIn ? 6 : 4}
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Typography variant="h5" color={"#fff"} textAlign={"center"} fontWeight={600} mb={1}>
                                Im Prozess
                            </Typography>
                            <Box height={"100%"} overflow={"auto"} pr={0.5}>
                                {ordersInProgress &&
                                ordersInProgress[user.client.locations[locationsTab].id].length > 0 ? (
                                    ordersInProgress[user.client.locations[locationsTab].id].map((order) => (
                                        <OrderAccordion key={order.id} order={order} refetchOrders={refetch} />
                                    ))
                                ) : (
                                    <NoData />
                                )}
                            </Box>
                        </Grid>
                    )}
                    {(!isMobile || statusTab === 2) &&
                        user.client.locations[locationsTab].serviceType !== ServiceType.DineIn && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={user.client.locations[locationsTab].serviceType === ServiceType.DineIn ? 6 : 4}
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography variant="h5" color={"#fff"} textAlign={"center"} fontWeight={600} mb={1}>
                                    Bereit zur Abholung
                                </Typography>
                                <Box height={"100%"} overflow={"auto"} pr={0.5}>
                                    {ordersReadyForPickup &&
                                    ordersReadyForPickup[user.client.locations[locationsTab].id].length > 0 ? (
                                        ordersReadyForPickup[user.client.locations[locationsTab].id].map((order) => (
                                            <OrderAccordion key={order.id} order={order} refetchOrders={refetch} />
                                        ))
                                    ) : (
                                        <NoData />
                                    )}
                                </Box>
                            </Grid>
                        )}
                </Grid>
            </Box>
        </Box>
    );
}
