import { getAuth } from "firebase/auth";
import { Messaging, getMessaging, isSupported } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAmbtzlPDFEF2xgAg1zKT6qq96sM31D_G0",
    authDomain: "fastlane-a868b.firebaseapp.com",
    projectId: "fastlane-a868b",
    storageBucket: "fastlane-a868b.appspot.com",
    messagingSenderId: "392676554874",
    appId: "1:392676554874:web:e173ae2206f0e6702e63c3",
};

const app = firebase.initializeApp(firebaseConfig);

export const messaging = isSupported().then((res) => {
    if (res) return getMessaging(app);
    else return {} as Messaging;
});
export const VAPID_KEY = "BMbZDZIjcl7WV4qlr257krLfm4GOeMAwflHeCLeeXbEg1cxJmKOwM86NKHHyfsIgq7Jm7hp4B6JRMmHjc362wl0";
export const auth = getAuth(app);
export default app;
