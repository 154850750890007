import { signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { api } from "../axios";
import { VAPID_KEY, auth, messaging } from "../firebase";
import { getServiceUser, registerDevice, unRegisterDevice } from "../services/user.service";
import { User } from "../types/User";
import { getToken, onMessage, isSupported } from "firebase/messaging";
import { useNavigate } from "react-router-dom";

async function requestNotificationPermission() {
    try {
        const permission = await Notification.requestPermission();
        console.log("Notification permission granted:", permission);
    } catch (error) {
        console.error("Error requesting notification permission:", error);
    }
}

interface _ {
    user: User | null;
    loading: boolean;
    signIn: (email: string, password: string) => Promise<User>;
    signOut: () => Promise<void>;
}

const UserAuthContext = createContext<_>({} as _);

export function UserAuthContextProvider({ children }: any) {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const signOut = useCallback(async () => {
        console.log("Here");
        try {
            if (await isSupported()) {
                const fcmToken = await getToken(await messaging, { vapidKey: VAPID_KEY });
                if (fcmToken) await unRegisterDevice(fcmToken);
            }
        } catch (err) {
            console.error(err);
        }
        console.log("Here 2");
        await auth.signOut();
        console.log("Here 3 ");
        delete api.defaults.headers.common["Authorization"];
        console.log("Here 4");
        navigate(`/sign-in`);
    }, [navigate]);

    const handleTokenExpiration = useCallback(async () => {
        await signOut();
    }, [signOut]);

    const refreshToken = useCallback(async () => {
        if (auth.currentUser) {
            try {
                const jwt = await auth.currentUser.getIdToken(true);
                api.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
            } catch (error: any) {
                if (error.code === "auth/id-token-expired") {
                    await handleTokenExpiration();
                } else {
                    console.error("Error refreshing token:", error);
                }
            }
        }
    }, [handleTokenExpiration]);

    const signIn = useCallback(async (email: string, password: string) => {
        await requestNotificationPermission();
        const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
        const jwt = await firebaseUser.getIdToken(true);
        api.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
        const userInfo = await getServiceUser(firebaseUser.uid);
        const user: User = {
            ...firebaseUser,
            ...userInfo,
        };
        setUser((prev) => ({ ...prev, ...user }));
        return user;
    }, []);

    const receiveMessage = useCallback(async (callback: (payload: any) => void) => {
        onMessage(await messaging, (payload) => {
            callback(payload);
        });
    }, []);

    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                try {
                    await requestNotificationPermission();
                    // User is signed in
                    const jwt = await currentUser.getIdToken(true);
                    api.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
                    const userInfo = await getServiceUser(currentUser.uid);
                    const user: User = {
                        ...currentUser,
                        ...userInfo,
                    };
                    setUser((prev) => ({ ...prev, ...user }));

                    if (await isSupported()) {
                        const fcmToken = await getToken(await messaging, { vapidKey: VAPID_KEY });
                        console.log("FCM Token:", fcmToken);
                        if (fcmToken) await registerDevice({ token: fcmToken, client: user.client });

                        ////Store token API

                        // Receive and handle FCM messages
                        receiveMessage((payload) => {
                            console.log("Received FCM message:", payload);
                            // Handle the incoming message as needed
                        });
                    }
                } catch (error) {
                    console.log("error while signing in", error);
                }
            } else {
                // User is signed out
                setUser(null);
                delete api.defaults.headers.common["Authorization"];
            }

            // Loading is complete when we get the first callback from `onAuthStateChanged`
            setLoading(false);
        });

        // Cleanup the subscription when the component is unmounted
        return () => unsubscribe();
    }, [receiveMessage]);

    useEffect(() => {
        const handleUserInteraction = async () => {
            try {
                await refreshToken();
            } catch (error: any) {
                if (error.code === "auth/id-token-expired") {
                    await handleTokenExpiration();
                } else {
                    console.error("Error handling user interaction:", error);
                }
            }
        };

        // List of events to detect user interaction
        const events = ["mousedown", "mousemove", "keydown", "scroll"];
        events.forEach((event) => window.addEventListener(event, handleUserInteraction, { once: true }));

        // Cleanup the event listeners
        return () => {
            events.forEach((event) => window.removeEventListener(event, handleUserInteraction));
        };
    }, [handleTokenExpiration, refreshToken]);

    return <UserAuthContext.Provider value={{ user, signOut, signIn, loading }}>{children}</UserAuthContext.Provider>;
}

export function useUserAuth() {
    return useContext(UserAuthContext);
}
