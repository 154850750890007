import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/AuthContext";
import Loader from "./Loader/Loader";

export default function Protected({ children }: any) {
    const { user, loading } = useUserAuth();

    if (loading) {
        return <Loader />;
    }

    if (!user) {
        console.log("user not logged in");
        return <Navigate to="/sign-in" replace />;
    }
    return <>{children}</>;
}
