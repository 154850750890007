import React, { useEffect } from "react";
import "./App.css";
import { Navigate, useRoutes } from "react-router-dom";
import OrderOverviewPage from "./pages/OrderOverviewPage";
import SignInPage from "./pages/SignInPage";
import Protected from "./components/Protected";

function App(): JSX.Element {
    useEffect(() => {
        document.title = "Fastlane Order Management App";
    }, []);

    const routes = useRoutes([
        {
            path: "/:clientId",
            element: (
                <Protected>
                    <OrderOverviewPage />
                </Protected>
            ),
        },
        { path: "/sign-in", element: <SignInPage /> },
        { path: "*", element: <Navigate to="/sign-in" /> },
    ]);

    return <>{routes}</>;
}

export default App;
